<template>
 <div class="footer-box">
    <a target="_blank" href="https://weibo.com/izuk" class="footer-list">
        <div class="top-logo">
            <img class="img1" src="https://cdnzui.lenovomm.cn/web/zui14_pc/static/img/foot-logo1.png?jkdjkd" alt="">
            <img class="img2" src="https://cdnzui.lenovomm.cn/web/zui14_pc/static/img/foot-logo12.png?jkdjkd" alt="">
        </div>
        <p class="p1">新浪微博</p>
    </a>
    <a target="_blank" class="footer-list">
        <div class="top-logo">
            <img class="img1" src="https://cdnzui.lenovomm.cn/web/zui14_pc/static/img/foot-logo2.png?jkdjkd" alt="">
            <img class="img2" src="https://cdnzui.lenovomm.cn/web/zui14_pc/static/img/foot-logo22.png?jkdjkd" alt="">
        </div>
        <p class="p1">官方微信</p>
        <div class="erwei-code">
            <img src="../assets/img/erwei-code.png" alt="">
        </div>
    </a>
    <a target="_blank" href="https://www.zui.com/cloudservice" class="footer-list">
        <div class="top-logo">
            <img class="img1" src="https://cdnzui.lenovomm.cn/web/zui14_pc/static/img/foot-logo3.png?jkdjkd" alt="">
            <img class="img2" src="https://cdnzui.lenovomm.cn/web/zui14_pc/static/img/foot-logo32.png?jkdjkd" alt="">
        </div>
        <p class="p1">云服务</p>
    </a>
    <a target="_blank" href="https://www.zui.com/iunlock" class="footer-list">
        <div class="top-logo">
            <img class="img1" src="https://cdnzui.lenovomm.cn/web/zui14_pc/static/img/foot-logo4.png?jkdjkd" alt="">
            <img class="img2" src="https://cdnzui.lenovomm.cn/web/zui14_pc/static/img/foot-logo42.png?jkdjkd" alt="">
        </div>
        <p class="p1">解锁</p>
    </a>
    <a target="_blank" href="https://cdnzui.lenovomm.cn/web/book/Lenovo_Tablet_Document.zip" class="footer-list">
        <div class="top-logo">
            <img class="img1" src="https://cdnzui.lenovomm.cn/web/zui14_pc/static/img/foot-logo5.png?jkdjkd" alt="">
            <img class="img2" src="https://cdnzui.lenovomm.cn/web/zui14_pc/static/img/foot-logo52.png?jkdjkd" alt="">
        </div>
        <p class="p1">开发者计划</p>
    </a>
</div>
</template>
<script>
export default {

};
</script>
<style scoped>
.footer-box{
	width: 100%;
	height: 4rem;
	background: #f5f5f5;
	position: relative;
	z-index: 1;
	/* overflow: hidden; */
}
.footer-box .footer-list{
	display: block;
	float: left;
	padding:1.4rem 0 0 0;
	cursor: pointer;
	position: relative;
}
.footer-box .footer-list .erwei-code{
	position: absolute;
    top: -6.44rem;
    left: -2.5rem;
	width: 6.55rem;
	display: none;
}
.footer-box .footer-list:hover .erwei-code{
	display: block;
}
.footer-box .footer-list .erwei-code img{
	width: 6.55rem !important;
}
.footer-box .footer-list .p1{
	font-family: FZLanTingHei-DB-GBK;
	font-weight: 400;
	color: #B1B1B1;
	font-size: .26rem;
	transition: all 0.3s;
}
.footer-box .footer-list .top-logo{
	height:.95rem;
}
.footer-box .footer-list:hover .p1{
	color: #000000;
	text-decoration: underline;
}
.footer-box .footer-list .img1{
	display: block;
}
.footer-box .footer-list .img2{
	display: none;
}
.footer-box .footer-list:hover .img1{
	display: none;
}
.footer-box .footer-list:hover .img2{
	display: block;
}

.footer-box .footer-list:nth-of-type(1){
	margin-left:7.23rem;
}
.footer-box .footer-list:nth-of-type(1) img{
	width: .73rem;
	margin: 0 auto;
}
.footer-box .footer-list:nth-of-type(2){
	margin-left:4.8rem;
}
.footer-box .footer-list:nth-of-type(2) img{
	width: .72rem;
	margin: 0 auto;
}
.footer-box .footer-list:nth-of-type(3){
	margin-left:4.8rem;
}
.footer-box .footer-list:nth-of-type(3) img{
	width: .73rem;
	margin: 0 auto;
}
.footer-box .footer-list:nth-of-type(4){
	margin-left:4.8rem;
}
.footer-box .footer-list:nth-of-type(4) img{
	width: .51rem;
	margin: 0 auto;
}
.footer-box .footer-list:nth-of-type(5){
	margin-left:4.8rem;
}
.footer-box .footer-list:nth-of-type(5) img{
	width: .77rem;
	margin: 0 auto;
}
</style>
